import moment from "moment";
import { saveAs } from 'file-saver';
import { isNil, noop } from "lodash";
import { allBWICSActions as actionTypes } from '../constants';
import { bwicService } from '../services';
import { errorActions, searchSecuritiesActions } from '.';
import { queryStringSerializer } from '../utils/filtering';
import {
    identifiers, ratings, statuses, multipleCurrencies, dateFrom, dateTo, types, sizeFrom, sizeTo, collateralTypes,
    esg, euCompliance, staticDeals, reinvestmentEndFrom, reinvestmentEndTo, nonCallEndFrom, nonCallEndTo,
    collateralManagers, mixedDateRange, trustees, amr, outOfNC, outOfRI, sorting, color, colorFrom, colorTo,
} from '../utils/filtering/serializers/serializer.definitions';
import { controlPanelActions } from './control-panel.actions';
import { isRequestCancelationError } from './error.actions';
import { AllBwicsTab } from '../components/allBWICS/AllBwicsTab';
import { BwicFilterType } from '../types/filters/FilterState';
import { createFilterActions } from './filter.actions';
import { isRequesting, numericUtils } from '../utils';
import { dateRangeFilterOptions } from '../constants/date-range.filter';
import { convertToApiCriteria, getDateOptionByRange, getYearsRange } from '../utils/filtering/filter.utils';
import { selectedDateOptions } from '../utils/amr-pipeline-filter.utils';
import { PageConfigType } from "../types/page-config/PageConfigType";
import { user } from "../user";
import { SubscriptionFeature } from "../types/billing/SubscriptionFeature";
import { DeserializeCommandHandler } from "../utils/filtering/serializers/DeserializeCommand";
import { bwicMonitorFilters } from "../types/state/AllBwicsState";
import { RequestState } from "../constants/request-state";
import { history } from "../history";
import { ColorFilterType } from "../types/bwic/Color";

export const allBWICSActions = {
    init,
    reset,
    searchBwics,
    setMyBWICsFlag,
    setActiveBwic,
    setBwicCollapsed,
    setCollapseAll,
    exportBwics,
    loadNextPage,
    liveBiddingStage2Expired,
    addPositionBids,
    sortChanged,
    advancedFilterBlocked
};

function init(myBwicsOnly, isAllToAll, filterQueryString, bwicMonitorTab) {
    return dispatch => {
        dispatch(setMyBWICsFlag(myBwicsOnly));
        dispatch(setAllToAllBwicsFlag(isAllToAll));
        dispatch(setDefaultFilter(filterQueryString, bwicMonitorTab));
        dispatch(searchBwics(bwicMonitorTab, true));
    };
}

function getFilterState(state, bwicMonitorTab) {
    switch (bwicMonitorTab) {
        case AllBwicsTab.Buy:
            return state.bwicMonitorBuy;
        case AllBwicsTab.Sell:
            return state.bwicMonitorSell;
        default:
            return state.bwicMonitor
    }
}

function getFilterType(bwicMonitorTab) {
    switch (bwicMonitorTab) {
        case AllBwicsTab.Buy:
            return BwicFilterType.BwicMonitorBuy;
        case AllBwicsTab.Sell:
            return BwicFilterType.BwicMonitorSell;
        default:
            return BwicFilterType.BwicMonitor
    }
}

function setDefaultFilter(filterQueryString, bwicMonitorTab) {
    return (dispatch, getState) => {
        const queryString = filterQueryString && filterQueryString.trim();
        const filterType = getFilterType(bwicMonitorTab);
        const filterActions = createFilterActions(filterType);
        dispatch(filterActions.resetFilter());
        dispatch(filterActions.resetFiltersVisibility());
        let defaultConfigFilter;

        dispatch(advancedFilterBlocked(false)); // reset blocked state

        const canUseSavedFilters = user.hasFeatures(SubscriptionFeature.BwicMonitorSavedFilters);

        if (filterType === BwicFilterType.BwicMonitor && canUseSavedFilters) {
            const filters = getState().entities.pageConfig.configs?.[PageConfigType.BwicMonitorAll]?.filters;
            defaultConfigFilter = filters?.find(filter => filter.default)
        }

        const filterState = getFilterState(getState().filters, bwicMonitorTab);
        // Call init after all dynamic filters (managers, broker-dealers) have already been loaded.
        const filterToInit = filterState.initialFilter ? filterState.initialFilter : filterState.filter;

        dispatch(filterActions.init({
            ...filterToInit,
            date: bwicMonitorTab === AllBwicsTab.All
                ? bwicMonitorFilters.defaultFilters().date
                : filterToInit.date
        }))

        const canUseHistoricalData = user.hasFeatures(SubscriptionFeature.BwicMonitorHistoricalData);

        if (queryString?.length) {
            let from, to, sizeMin, sizeMax, maturityFrom, maturityTo, vintageFrom, vintageTo, closingFrom, closingTo,
                trusteesValues, amrValue, ratingsValues, statusValues, currencyValues, collateralTypeValues,
                managerValues, euComplianceValue, esgValue, staticDealValue, sortBy, colors, colorRangeFrom, colorRangeTo;

            const collateralManagerReferenceNames = getState()
                .entities
                .collateralManagers
                .items
                .map(x => x.referenceName);

            dispatch(filterActions.resetFilter());

            function applyRadio(flag, key) {
                if (flag && key) {
                    dispatch(filterActions.filterVisibilityChange(key));
                    dispatch(filterActions.filterRadioChange(true, key));
                }
            }

            const [ncEndSerializers, getNcEndDeserializeResult] = mixedDateRange('nonCallEndFrom', 'nonCallEndTo');
            const [riEndSerializers, getRiEndDeserializeResult] = mixedDateRange('reinvestmentEndFrom', 'reinvestmentEndTo');

            const identifieList = [];

            const serializers = [
                identifiers(list => list.forEach(i => identifieList.push(i))),
                dateFrom(date => from = date),
                dateTo(date => to = date),
                ratings(ratings => ratingsValues = ratings),
                statuses(statuses => statusValues = statuses),
                multipleCurrencies(currencies => currencyValues = currencies),
                sizeFrom(min => sizeMin = min),
                sizeTo(max => sizeMax = max),
                collateralTypes(types => collateralTypeValues = types),
                esg(value => esgValue = value),
                euCompliance(value => euComplianceValue = value),
                staticDeals(value => staticDealValue = value),
                ...ncEndSerializers,
                ...riEndSerializers,
                collateralManagers(managers => managerValues = managers, collateralManagerReferenceNames),
                dateFrom(value => maturityFrom = value, 'maturityFrom'),
                dateTo(value => maturityTo = value, 'maturityTo'),
                dateFrom(value => vintageFrom = value, 'vintageFrom'),
                dateTo(value => vintageTo = value, 'vintageTo'),
                dateFrom(value => closingFrom = value, 'closingFrom'),
                dateTo(value => closingTo = value, 'closingTo'),
                trustees(values => trusteesValues = values),
                amr(value => amrValue = value),
                sorting(value => sortBy = value),
                color(values => colors = values.map(c => +c)),
                colorFrom(value => colorRangeFrom = value),
                colorTo(value => colorRangeTo = value)
            ];

            queryStringSerializer.deserialize(queryString, serializers);

            const ncEndDeserializeResult = getNcEndDeserializeResult();
            const riEndDeserializeResult = getRiEndDeserializeResult();

            const canUseAdvancedFilters = user.hasFeatures(SubscriptionFeature.BwicMonitorAvancedFilters);

            const identifiersCommand = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !!identifieList.length,
                execute: () => identifieList.forEach(i => dispatch(searchSecuritiesActions.addSearchItem(i)))
            };
            const ncEnd = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !!ncEndDeserializeResult?.dates || !!ncEndDeserializeResult?.years,
                execute: () => dispatch(filterActions.filterDateQueryStringDeserialized('nonCallEnd', ncEndDeserializeResult))
            };
            const riEnd = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !!riEndDeserializeResult?.dates || !!riEndDeserializeResult?.years,
                execute: () => dispatch(filterActions.filterDateQueryStringDeserialized('reinvestmentEnd', riEndDeserializeResult))
            };
            const ratingsCommand = {
                shouldExecute: () => !!ratingsValues,
                execute: () => ratingsValues.forEach(r => dispatch(filterActions.filterSelectChange(r, 'ratings')))
            };
            const statusesCommand = {
                shouldExecute: () => !!statusValues,
                execute: () => statusValues.forEach(s => dispatch(filterActions.filterSelectChange(Number(s), 'statuses')))
            };
            const currencies = {
                shouldExecute: () => !!currencyValues,
                execute: () => currencyValues.forEach(c => dispatch(filterActions.filterSelectChange(c, 'currency')))
            };
            const callatTypes = {
                shouldExecute: () => !!collateralTypeValues,
                execute: () => collateralTypeValues.forEach(t => dispatch(filterActions.filterSelectChange(t, 'collateralType')))
            };
            const managers = {
                shouldExecute: () => !!managerValues,
                execute: () => managerValues.forEach(m => dispatch(filterActions.filterSelectChange(m, 'managers')))
            };
            const size = {
                shouldExecute: () => numericUtils.isNumber(sizeMin) || numericUtils.isNumber(sizeMax),
                execute: () => dispatch(filterActions.changeRangeFilter({ from: sizeMin, to: sizeMax }, 'size'))
            };

            const selectedDateOption = getDateOptionByRange(dateRangeFilterOptions.All, from, to);
            const date = {
                canExecute: () =>
                    canUseHistoricalData ||
                    (selectedDateOption.key === dateRangeFilterOptions.TodayAndUpcoming.key && bwicMonitorTab === AllBwicsTab.All),
                shouldExecute: () => !!from || !!to,
                execute: () => {
                    if (selectedDateOption.key === dateRangeFilterOptions.All.key) {
                        selectedDateOption.default = true;
                    }
                    dispatch(filterActions.filterDateSelectOption(selectedDateOption, 'date'));
                    dispatch(filterActions.filterDateSelectCustomRange({ from, to }, 'date'));
                }
            };
            const euComplianceCommand = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !!euComplianceValue,
                execute: () => applyRadio(euComplianceValue, 'euCompliance')
            };
            const esgCommand = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !!esgValue,
                execute: () => applyRadio(esgValue, 'esg')
            };
            const staticDeal = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !!staticDealValue,
                execute: () => applyRadio(staticDealValue, 'staticDeal')
            };
            const trusteesCommand = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !!trusteesValues?.length,
                execute: () => {
                    dispatch(filterActions.filterVisibilityChange('trustees'));
                    trusteesValues.forEach(name => dispatch(filterActions.filterSelectChange(name, 'trustees')));
                }
            };
            const maturity = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !isNil(maturityFrom) || !isNil(maturityTo),
                execute: () => {
                    dispatch(filterActions.makeFilterVisible('maturity'));
                    const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, maturityFrom, maturityTo);
                    dispatch(filterActions.filterDateSelectOption(filterOption, 'maturity'));
                    dispatch(
                        filterActions.filterDateSelectCustomRange({ from: maturityFrom, to: maturityTo }, 'maturity')
                    );
                }
            };
            const vintage = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !isNil(vintageFrom) || !isNil(vintageTo),
                execute: () => {
                    dispatch(filterActions.makeFilterVisible('vintage'));
                    const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, vintageFrom, vintageTo);
                    dispatch(filterActions.filterDateSelectOption(filterOption, 'vintage'));
                    dispatch(
                        filterActions.filterDateSelectCustomRange({ from: vintageFrom, to: vintageTo }, 'vintage'),
                    );
                }
            };
            const closing = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !isNil(closingFrom) || !isNil(closingTo),
                execute: () => {
                    dispatch(filterActions.makeFilterVisible('closing'));
                    const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, closingFrom, closingTo);
                    dispatch(filterActions.filterDateSelectOption(filterOption, 'closing'));
                    dispatch(
                        filterActions.filterDateSelectCustomRange({ from: closingFrom, to: closingTo }, 'closing'),
                    );
                }
            };
            const arm = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !isNil(amrValue),
                execute: () => {
                    dispatch(filterActions.filterVisibilityChange('amr'));
                    dispatch(filterActions.filterRadioChange(amrValue, 'amr'));
                }
            };

            const isTradedSelected = colors?.some(c => c === ColorFilterType.Traded);
            let isTradedColorSelected = colors?.some(c => c === ColorFilterType.TradedColor);

            if(isTradedSelected && isTradedColorSelected) {
                colors = colors.filter(c => c !== ColorFilterType.TradedColor);
                isTradedColorSelected = false;
            }

            const colorCommand = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => !!colors?.length,
                execute: () => {
                    dispatch(filterActions.makeFilterVisible('color'));
                    dispatch(filterActions.makeFilterVisible('tradedColor'));
                    colors.forEach(s => dispatch(filterActions.filterSelectChange(Number(s), 'color')))
                }
            };

            const colorRangeCommand = {
                canExecute: () => canUseAdvancedFilters,
                shouldExecute: () => isTradedColorSelected && (!isNil(colorRangeFrom) || !isNil(colorRangeTo)),
                execute: () => dispatch(filterActions.changeRangeFilter({ from: colorRangeFrom, to: colorRangeTo }, 'tradedColor'))
            };

            const sortByCommand = {
                canExecute: () => user.hasFeatures(SubscriptionFeature.BwicMonitorSorting),
                shouldExecute: () => !!sortBy,
                execute: () => dispatch(sortFieldChanged(sortBy))
            }

            const isAllCommandsExecuted =
                new DeserializeCommandHandler()
                    .addCommand(identifiersCommand)
                    .addCommand(ratingsCommand)
                    .addCommand(statusesCommand)
                    .addCommand(currencies)
                    .addCommand(callatTypes)
                    .addCommand(managers)
                    .addCommand(size)
                    .addCommand(date)
                    .addCommand(euComplianceCommand)
                    .addCommand(esgCommand)
                    .addCommand(staticDeal)
                    .addCommand(trusteesCommand)
                    .addCommand(maturity)
                    .addCommand(vintage)
                    .addCommand(closing)
                    .addCommand(arm)
                    .addCommand(ncEnd)
                    .addCommand(riEnd)
                    .addCommand(sortByCommand)
                    .addCommand(colorCommand)
                    .addCommand(colorRangeCommand)
                    .process()

            if (!isAllCommandsExecuted) {
                dispatch(advancedFilterBlocked(true));
            }
        } else if (defaultConfigFilter) {
            dispatch(filterActions.selectFilterFromConfig(defaultConfigFilter));
        } else if (canUseHistoricalData) { // Skip settings "TodayAndUpcomming" because it is set by default in the filter state
            dispatch(filterActions.filterDateSelectOption({
                ...dateRangeFilterOptions.TodayAndUpcoming,
                from: moment().toDate(),
                to: null
            }, 'date'));
            dispatch(filterActions.filterDateSelectCustomRange({ from: moment().toDate(), to: null }, 'date'));
        }
    };
}

function advancedFilterBlocked(blocked) {
    return {
        type: actionTypes.ADVANCED_FILTER_BLOCKED,
        blocked
    }
}

function resetSummary() {
    return { type: actionTypes.RESET_SUMMARY }
}

function searchBwics(bwicMonitorTab, applyFilter = false, isSearchTermChange = false) {
    return (dispatch, getState) => {
        const { isNextPageRequesting, advancedFilterBlocked, summary } = getState().allBWICS;
        const filterType = getFilterType(bwicMonitorTab);
        const filterActions = createFilterActions(filterType);

        if (advancedFilterBlocked) return;

        if (!isNextPageRequesting) {
            dispatch(resetPaging());
            dispatch(bwicsSearchProgress(RequestState.request));
            dispatch(controlPanelActions.hide());
        }

        const isFilterChanged = applyFilter || isSearchTermChange;

        if(isFilterChanged && summary){
            dispatch(resetSummary());
        }

        const { currentPageNumber, pageSize, myBwicsOnly, isAllToAll, sortBy } = getState().allBWICS;
        const { searchTermItems } = getState().searchSecurities;
        const filterState = getFilterState(getState().filters, bwicMonitorTab);
        const lastAppliedFilter = filterState.lastAppliedFilter
        const filter = (!applyFilter && lastAppliedFilter) || filterState.filter;
        const criteria = getFilterCriteria(filter, searchTermItems, myBwicsOnly, isAllToAll);
        const requestingPage = isNextPageRequesting ? currentPageNumber + 1 : currentPageNumber;

        bwicService
            .searchBwics(criteria, requestingPage, pageSize, sortBy, isFilterChanged)
            .then(success, failure)
            .finally(() => {
                dispatch(nextPageRequesting(false));
            })

        function success({ totalRecordNumber, result, ...summaryProps }) {
            // Manually add filters to support query string serialization
            criteria.currency = filter.currency.filter.filter(c => c.selected).map(c => c.value);
            criteria.nonCallEndFrom = criteria.nonCallEndFromYear ?? criteria.nonCallEndFromDate;
            criteria.nonCallEndTo = criteria.nonCallEndToYear ?? criteria.nonCallEndToDate;
            criteria.reinvestmentEndFrom = criteria.reinvestmentEndFromYear ?? criteria.reinvestmentEndFromDate;
            criteria.reinvestmentEndTo = criteria.reinvestmentEndToYear ?? criteria.reinvestmentEndToDate;
            criteria.collateralManagers = criteria.managers;

            const { color = {} } = criteria;
            criteria.color = color.filters;
            criteria.colorFrom = color.min;
            criteria.colorTo = color.max;

            if (applyFilter) {
                dispatch(filterActions.applyFilter());
            }

            dispatch(bwicsSearchProgress(RequestState.success));
            dispatch(redirectWithFilterArguments(criteria, sortBy));
            dispatch({
                type: actionTypes.BWICS_SEARCH_COMPLETE,
                bwics: result || [],
                totalRecordNumber,
                isNextPageRequesting,
                summary: isFilterChanged ? summaryProps : undefined
            });
        }

        function failure(e) {
            if (!isRequestCancelationError(e)) {
                dispatch(bwicsSearchProgress(RequestState.failure));
                dispatch(errorActions.criticalError(e));
            }
        }
    };
}

function redirectWithFilterArguments(filter, sortBy) {
    return () => {
        const serializers = [
            identifiers(), ratings(), statuses(), multipleCurrencies(), dateFrom(), collateralManagers(),
            dateTo(), types(), sizeFrom(), sizeTo(), collateralTypes(), esg(), euCompliance(),
            staticDeals(), reinvestmentEndFrom(), reinvestmentEndTo(), nonCallEndFrom(), nonCallEndTo(),
            dateFrom(noop, 'maturityFrom'), dateTo(noop, 'maturityTo'), dateFrom(noop, 'vintageFrom'),
            dateTo(noop, 'vintageTo'), dateFrom(noop, 'closingFrom'), dateTo(noop, 'closingTo'),
            trustees(), amr(), outOfNC(), outOfRI(), color(), colorFrom(), colorTo()
        ];

        if (user.hasFeatures(SubscriptionFeature.BwicMonitorSorting)) {
            serializers.push(sorting())
        }

        const queryString = queryStringSerializer.serialize({ ...filter, sortBy }, serializers);

        if (queryString) {
            history.replace(history.location.pathname + '?' + queryString);
        }
    };
}

function getFilterCriteria(filter, isinCusipsAndTickers, myBwicsOnly, isAllToAll) {
    const defaultDateRange = { dateFrom: undefined, dateTo: undefined };

    const selectedCurrencies = filter.currency.filter.filter(c => c.selected).map(c => c.value);
    const nonCallEndYears = getYearsRange(filter.nonCallEnd);
    const nonCallEndDates = selectedDateOptions(filter.nonCallEnd);
    const reinvestmentEndYears = getYearsRange(filter.reinvestmentEnd);
    const reinvestmentEndDates = selectedDateOptions(filter.reinvestmentEnd);

    const maturityRange = filter.maturity?.filter.selectedOption
        ? convertToApiCriteria(filter.maturity.filter.selectedOption, filter.maturity.filter.options)
        : defaultDateRange;
    const vintageRange = filter.vintage?.filter.selectedOption
        ? convertToApiCriteria(filter.vintage.filter.selectedOption, filter.vintage.filter.options)
        : defaultDateRange;
    const closingRange = filter.closing?.filter.selectedOption
        ? convertToApiCriteria(filter.closing.filter.selectedOption, filter.closing.filter.options)
        : defaultDateRange;

    return {
        isinCusipsAndTickers,
        myBwicsOnly,
        isAllToAll,
        statuses: filter.statuses.filter.filter(f => f.selected).map(f => f.value),
        ratings: filter.ratings.filter.filter(r => r.selected).map(r => r.value),
        currency: selectedCurrencies.length && selectedCurrencies.length === 1 ? selectedCurrencies[0] : undefined,
        collateralTypes: filter.collateralType?.filter.filter(c => c.selected).map(c => c.value),
        managers: filter.managers?.filter.filter(c => c.selected).map(c => c.value),
        esg: filter.esg?.filter.selectedOption,
        staticDeals: filter.staticDeal?.filter.selectedOption,
        euCompliance: filter.euCompliance?.filter.selectedOption,
        nonCallEndFromYear: nonCallEndYears?.from,
        nonCallEndToYear: nonCallEndYears?.to,
        nonCallEndFromDate: nonCallEndDates?.from,
        nonCallEndToDate: nonCallEndDates?.to,
        reinvestmentEndFromYear: reinvestmentEndYears?.from,
        reinvestmentEndToYear: reinvestmentEndYears?.to,
        reinvestmentEndFromDate: reinvestmentEndDates?.from,
        reinvestmentEndToDate: reinvestmentEndDates?.to,
        sizeFrom: filter.size.filter.from,
        sizeTo: filter.size.filter.to,
        ...convertToApiCriteria(filter.date.filter.selectedOption, filter.date.filter.options),
        maturityFrom: maturityRange.dateFrom,
        maturityTo: maturityRange.dateTo,
        vintageFrom: vintageRange.dateFrom,
        vintageTo: vintageRange.dateTo,
        closingFrom: closingRange.dateFrom,
        closingTo: closingRange.dateTo,
        trustees: filter.trustees?.filter.filter(f => f.selected).map(f => f.value),
        amr: filter.amr?.filter.selectedOption,
        outOfNC: filter.outOfNC?.filter.selectedOption,
        outOfRI: filter.outOfRI?.filter.selectedOption,
        color: filter.color?.filter.some(r => r.selected) ? {
            filters: filter.color.filter.filter(r => r.selected).map(r => r.value),
            min: numericUtils.isNumber(filter.tradedColor.filter.from) ? Number(filter.tradedColor.filter.from) : undefined,
            max: numericUtils.isNumber(filter.tradedColor.filter.to) ? Number(filter.tradedColor.filter.to) : undefined,
        }: undefined
    };
}

function setAllToAllBwicsFlag(isAllToAll) {
    return {
        type: actionTypes.ALL_TO_ALL,
        isAllToAll
    };
}

function setMyBWICsFlag(myBwicsOnly) {
    return {
        type: actionTypes.MY_BWICS,
        myBwicsOnly
    };
}

function bwicsSearchProgress(status) {
    return {
        type: actionTypes.BWICS_SEARCH_PROGRESS,
        status
    };
}

function setBwicCollapsed(referenceName, collapsed) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_BWIC_COLLAPSED_STATE,
            bwic: {
                referenceName,
                collapsed
            }
        });

        const { bwics, collapseAll } = getState().allBWICS;
        const collapsedCount = bwics.filter(b => b.collapsed).length;
        if (collapsedCount === bwics.length && !collapseAll) {
            dispatch(setCollapseAll(true));
        } else if (collapsedCount === 0 && collapseAll) {
            dispatch(setCollapseAll(false));
        }
    };
}

function setCollapseAll(collapseAll) {
    return {
        type: actionTypes.SET_COLLAPSE_ALL,
        collapseAll
    };
}

function setActiveBwic(bwicReferenceName) {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_ACTIVE_BWIC,
            bwicReferenceName
        });
    };
}

function setExportingBwicStatus(status) {
    return { type: actionTypes.ALL_BWICS_SET_EXPORTING_STATUS, payload: { status } }
}

function exportBwics(bwicMonitorTab) {
    return (dispatch, getState) => {
        const { currentPageNumber, pageSize, myBwicsOnly, isAllToAll, sortBy } = getState().allBWICS;
        const { searchTermItems } = getState().searchSecurities;
        const filterState = getFilterState(getState().filters, bwicMonitorTab);
        const lastAppliedFilter = filterState.lastAppliedFilter
        const criteria = getFilterCriteria(lastAppliedFilter, searchTermItems, myBwicsOnly, isAllToAll);
        criteria.isGroupView = true;

        dispatch(setExportingBwicStatus(true));
        bwicService
            .exportBwics(criteria, currentPageNumber, pageSize, sortBy)
            .then(file => saveAs(file.blob, file.name))
            .catch((e) => errorActions.unexpectedError(e))
            .finally(() => dispatch(setExportingBwicStatus(false)));
    };
}

function reset() {
    return { type: actionTypes.RESET };
}

function resetPaging() {
    return {
        type: actionTypes.ALL_BWICS_RESET_PAGING
    };
}

function nextPageRequesting(isRequesting) {
    return {
        type: actionTypes.ALL_BWICS_NEXT_PAGE_REQUESTING,
        isRequesting
    }
}

function loadNextPage(bwicMonitorTab) {
    return (dispatch, getState) => {
        const { searchInProgress, isNextPageRequesting } = getState().allBWICS;

        if (!isRequesting(searchInProgress) && !isNextPageRequesting) {
            dispatch(nextPageRequesting(true));
            dispatch(searchBwics(bwicMonitorTab));
        }
    }
}

function liveBiddingStage2Expired(positionId) {
    return {
        type: actionTypes.LIVE_BIDDING_STAGE2_EXPIRED,
        positionId
    };
}

function addPositionBids(bwicReferenceName, positionId, bids) {
    return {
        type: actionTypes.ADD_POSITION_BIDS,
        bwicReferenceName,
        positionId,
        bids
    }
}

function sortFieldChanged(sortBy) {
    return {
        type: actionTypes.SORTING_CHANGED,
        sortBy
    }
}

function sortChanged(sortBy, bwicMonitorTab) {
    return (dispatch) => {
        dispatch(sortFieldChanged(sortBy))
        dispatch(searchBwics(bwicMonitorTab))
    }
}
